<template>
  <CaModal
    :show="show"
    title="Selecione sua região"
    class="region-modal"
    btn-label="Confirmar"
    :btn-loading="isLoading"
    data-testid="region-modal"
    :btn-disabled="!selectedRegion"
    @keyup.enter="confirmRegion"
    @btn-click="confirmRegion"
    @close="onClose"
  >
    <div
      v-if="isLoading"
      class="region-modal__content flex justify-content-center"
    >
      <CaLoading />
    </div>

    <div
      v-else
      class="region-modal__content"
    >
      <div class="region-modal__search-input">
        <CaInputSearch
          v-model="inputQuery"
          placeholder="Digite para filtrar as regiões"
          clear-btn
          search-icon
        />
      </div>
      <div
        v-if="filteredRegions"
        class="region-modal__search-result"
      >
        <ul class="region-modal__search-result__list">
          <li
            v-for="item in filteredRegions"
            :key="`region-${item.slug}`"
            class="region-modal__search-result__list-item p-5"
            @click="setRegion(item)"
          >
            <span
              class="region-modal__search-result__list-item-text"
              :class="{
                'region-modal__search-result__list-item-text--selected':
                  item.slug === selectedRegion.slug,
              }"
            >
              {{ item.name }}
            </span>
            <CaIcon
              v-if="item.slug === selectedRegion.slug"
              class="region-modal__search-result__list-item-icon"
              name="check"
              color="primary"
              :size="24"
            />
          </li>
          <li class="region-modal__search-result__list-item py-4">
            <CaLink
              target="_blank"
              href="https://materiais.petlove.com.br/estamos-chegando"
              class="full-width full-height"
            >
              Não encontrei minha região
            </CaLink>
          </li>
        </ul>
      </div>
    </div>
  </CaModal>
</template>

<script>
import { sanitize } from '@petlove/frontend-utilities-utils';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    regions: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'selected-region'],
  data() {
    return {
      inputQuery: '',
      selectedRegion: '',
    };
  },
  computed: {
    filteredRegions() {
      if (this.inputQuery.length) {
        return this.regions.filter((region) => {
          const sanitizedRegion = sanitize(region.name);
          const sanitizedQuery = sanitize(this.inputQuery);

          return sanitizedRegion?.includes(sanitizedQuery);
        });
      }

      return this.regions;
    },
  },
  methods: {
    setRegion(region) {
      this.selectedRegion = region;
    },
    onClose() {
      this.$emit('close');
    },
    confirmRegion() {
      if (this.selectedRegion) {
        this.selectedRegion.firstAccess = false;
        this.$emit('selected-region', this.selectedRegion);
        this.onClose();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.region-modal {
  :deep() {
    footer button.button {
      width: 100%;
    }

    .modal__container {
      max-width: 448px;
    }
  }

  &__content {
    height: 372px;
    overflow: hidden;
  }

  &__search-input {
    padding: space(2) space(4) space(0);
  }
  &__search-result {
    &__list {
      max-height: 288px;
      overflow-y: auto;

      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: fontSize(body);
        font-weight: fontWeight(regular);
        line-height: leading(m);
        background-color: color(white);
        color: color(neutral, darkest);
        cursor: pointer;

        &:hover {
          font-weight: fontWeight(medium);
          background-color: color(neutral, bg);
          color: color(neutral, dark);
        }

        &-text {
          width: 100%;
          height: 100%;
        }

        &-text--selected {
          color: color(primary);
        }

        &-icon {
          margin-right: space(3);
        }
      }
    }
  }
}
</style>
